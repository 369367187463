<template>
  <div class="evaluation"  v-loading="submitLoading" :style="submitLoading ? 'overflow-y:hidden;' : 'overflow-y: scroll;'" element-loading-text="数据提交中" v-if="questionList && questionList[sort - 1]">
    <div class="progress">
      <el-row :gutter="10">
        <el-col :span="20"
          ><el-progress
            v-if="percentage"
            :percentage="percentage"
            :show-text="false"
            style="position: relative; top: 0.5rem"
            color="#73986D"
          ></el-progress
        ></el-col>
        <el-col :span="4">
          <p class="sortBox">
            <span class="sort">{{ sort }}</span
            ><span class="quesLen">/{{ questionList.length }}</span>
          </p>
        </el-col>
      </el-row>
    </div>
    <div class="main" v-if="questionList && questionList[sort - 1]">
      <div class="moduleDesc" v-if="questionList[sort - 1].moduleDesc">
        {{ questionList[sort - 1].moduleDesc }}
      </div>
      <div
        class="moduleQues"
        v-for="(item, index) in questionList[sort - 1].questionArray"
        :key="index"
      >
        <b class="quesInfo">
          <span v-if="questionList[sort - 1].questionArray.length > 1"
            >{{ index + 1 }}.</span
          >&nbsp;{{ item.quesInfo }}</b
        >
        <div class="ladder" v-if="item.quesInfo.includes('梯子')">
          <img src="../../assets/image/baicheng/ladder.jpg" alt="" />
        </div>
        <!-- 单选 -->
        <div
          class="radio"
          v-if="
            (item.answerType == 'A' || item.answerType == 'A2') &&
            item.quesInfo.includes('性别是') == false
          "
        >
          <ul>
            <li v-for="(each, idx) in item.optionArr" :key="idx">
              <div
                @click="
                  groupRadioCheck(
                    item,
                    each,
                    questionList[sort - 1].questionArray.length
                  )
                "
                :class="each.optionId == item.answer ? 'item active' : 'item'"
              >
                <p>{{ each.optionInfo }}</p>
              </div>
            </li>
          </ul>
        </div>
        <!-- 性别 -->
        <div
          class="gender"
          v-if="item.answerType == 'A' && item.quesInfo.includes('性别是')"
        >
          <ul>
            <li
              class="sex"
              v-for="(each, idx) in item.optionArr"
              :class="each.optionId == item.answer ? 'active' : ''"
              :key="idx"
              @click="
                groupRadioCheck(
                  item,
                  each,
                  questionList[sort - 1].questionArray.length
                )
              "
            >
              <img
                v-if="each.optionInfo == '男' && each.optionId == item.answer"
                src="../../assets/image/baicheng/sex_man_active.png"
                alt=""
              />
              <img
                v-if="each.optionInfo == '男' && each.optionId != item.answer"
                src="../../assets/image/baicheng/sex_man.png"
                alt=""
              />
              <img
                v-if="each.optionInfo == '女' && each.optionId == item.answer"
                src="../../assets/image/baicheng/sex_woman_active.png"
                alt=""
              />
              <img
                v-if="each.optionInfo == '女' && each.optionId != item.answer"
                src="../../assets/image/baicheng/sex_woman.png"
                alt=""
              />
              <span>{{ each.optionInfo }}</span>
            </li>
          </ul>
        </div>
        <!-- 圆圈评分题 -->
        <div class="score" v-if="item.answerType == 'A1'">
          <ul>
            <li
              v-for="(each, idx) in item.optionArr"
              :class="each.optionId == item.answer ? 'active' : ''"
              :key="idx"
              @click="
                groupRadioCheck(
                  item,
                  each,
                  questionList[sort - 1].questionArray.length
                )
              "
            >
              <span>{{ each.optionInfo }}</span>
            </li>
          </ul>
        </div>
        <!-- 输入框 -->
        <div class="inputBox" v-if="item.answerType == 'B'">
          <!-- 年龄 -->
          <el-input
            v-if="item.quesInfo.includes('年龄是')"
            style="width: 16rem"
            min="1"
            max="150"
            v-model="item.answer"
            type="number"
            placeholder=""
          ></el-input>
          <el-input
            v-else-if="item.quesInfo.includes('身高是')"
            style="width: 16rem"
            min="20"
            max="250"
            v-model="item.answer"
            type="number"
            placeholder=""
          ></el-input>
          <el-input
            v-else-if="item.quesInfo.includes('体重是')"
            style="width: 16rem"
            min="10"
            max="500"
            v-model="item.answer"
            type="number"
            placeholder=""
          ></el-input>
          <span class="unit" v-if="item.quesInfo.includes('年龄是')">岁</span>
          <span class="unit" v-else-if="item.quesInfo.includes('身高是')"
            >cm</span
          >
          <span class="unit" v-else-if="item.quesInfo.includes('体重是')"
            >kg</span
          >
        </div>
        <!-- 多选 -->
        <div class="checkbox" v-if="item.answerType == 'C'">
          <ul>
            <li
              v-for="(each, idx) in item.optionArr"
              :class="each.checked ? 'active' : ''"
              :key="idx"
            >
              <p>
                <el-checkbox
                  v-model="each.checked"
                  @change="checkboxCheck($event, item, each)"
                  >{{ each.optionInfo }}</el-checkbox
                >
              </p>
            </li>
          </ul>
        </div>
        <!-- 选择框 -->
        <div class="selectBox" v-if="item.answerType == 'B1'">
          <el-select
            v-model="item.hour"
            @change="getHour($event, item)"
            placeholder="请选择"
            size="small"
          >
            <el-option
              v-for="item in hourOptions"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option> </el-select
          ><span>小时</span>
          <el-select
            v-model="item.minute"
            @change="getMinute($event, item)"
            placeholder="请选择"
            size="small"
          >
            <el-option
              v-for="item in minOptions"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option> </el-select
          ><span>分钟</span>
        </div>
        <!-- 16宫格 -->
        <div class="grid" v-if="item.answerType == 'B2'">
          <!-- <el-row :gutter="5" style="text-align: center">
              <el-col
                class="colItem"
                :span="6"
                v-for="(each, idx) in gridOptions"
                :key="idx"
                ><div
                  :class="each == item.answer ? 'active' : 'bg-purple'"
                  @click="gridCheck(item, each)"
                ></div
              ></el-col>
            </el-row> -->
          <div class="blackboard">黑板</div>
          <ul class="topSite">
            <li>最左边</li>
            <li>中间靠左</li>
            <li>中间</li>
            <li>中间靠右</li>
            <li>最右边</li>
          </ul>
          <div class="mainSite">
            <ul class="leftSite">
              <li>
                <span>第一排</span>
              </li>
              <li>
                <span>靠前排</span>
              </li>
              <li>
                <span>中间靠前</span>
              </li>
              <li>
                <span>中间靠后</span>
              </li>
              <li>
                <span>靠后排</span>
              </li>
              <li>
                <span>最后一排</span>
              </li>
            </ul>
            <ul class="siteList">
              <li
                v-for="(each, idx) in gridOptions"
                :key="idx"
                :class="each == item.answer ? 'active' : ''"
                @click="gridCheck(item, each)"
              ></li>
            </ul>
          </div>
        </div>
        <div class="imgBox" v-if="item.answerType == 'A3'">
          <ul>
            <li
              v-for="(each, idx) in item.optionArr"
              :class="each.optionId == item.answer ? 'active' : ''"
              :key="idx"
              @click="groupRadioCheck(item, each)"
            >
              <img :src="each.optionInfo" alt="" />
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="btns">
        <span class="prev" @click="prev" v-if="sort > 1">上一题</span>
        <span class="next" @click="next" v-if="sort != questionList.length"
          >下一题</span
        >
        <span v-if="sort == questionList.length" class="submit" @click="submit"
          >提交</span
        >
      </div>
    </div>
  </div>
</template>
<script>
import { Toast } from 'mint-ui';
import { getQuestionObjectiveArray, saveObjectiveAns } from '@/api/index';
export default {
  data() {
    return {
      submitLoading:false,
      questionList: [],
      sort: 1,
      answerList: [],
      startTime: 0, //起始时间
      endTime: 0, //结束时间
      hourOptions: [0, 1, 2, 3, 4, 5, 6],
      minOptions: [0, 10, 20, 30, 40, 50],
      gridOptions: [
        1, 7, 13, 19, 25, 2, 8, 14, 20, 26, 3, 9, 15, 21, 27, 4, 10, 16, 22, 28,
        5, 11, 17, 23, 29, 6, 12, 18, 24, 30,
      ],
    };
  },
  computed: {
    percentage() {
      return parseInt((this.sort / this.questionList.length) * 100);
    },
  },
  methods: {
    //上一题
    prev() {
      if (this.sort <= 1) {
        return false;
      }
      this.sort--;
      document.querySelector('.evaluation').scrollTop = 0;
    },
    //下一题
    next() {
      if (this.sort >= this.questionList.length) {
        return false;
      }
      let noAnswer = this.questionList[this.sort - 1].questionArray.filter(
        (item) => item.answer == null || item.answer == ''
      );
      if (noAnswer.length > 0) {
        Toast('请完成当前问卷！');
      } else {
        this.sort++;
        document.querySelector('.evaluation').scrollTop = 0;
      }
    },
    getHour(val, item) {
      if (!item.minute) {
        item.minute = 0;
      }
      item.hour = val;
      this.$forceUpdate();
      item.answer = item.hour + '小时' + item.minute + '分钟';
    },
    getMinute(val, item) {
      if (!item.hour) {
        item.hour = 0;
      }
      item.minute = val;
      this.$forceUpdate();
      item.answer = item.hour + '小时' + item.minute + '分钟';
    },
    //获取问题
    getQuestion() {
      getQuestionObjectiveArray()
        .then((res) => {
          if (res.code === 0) {
            this.questionList = res.data.list;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    //单选
    groupRadioCheck(item, each) {
      item.answer = each.optionId;
      item.optionId = each.optionId;
      item.optionScore = each.optionScore;
      this.$forceUpdate();
      // if (quesLen == 1) {
      //   this.next();
      // }
    },
    //多选
    checkboxCheck(event, item, each) {
      let mutexArr = ['无人照顾', '以上都没有', '没有参加'];
      if (each.checked && mutexArr.includes(each.optionInfo)) {
        item.optionArr.forEach((option) => {
          if (option.optionInfo != each.optionInfo) {
            option.checked = false;
          }
        });
        this.$forceUpdate();
      } else if (
        each.checked &&
        !mutexArr.includes(each.optionInfo) &&
        mutexArr.includes(
          item.optionArr[item.optionArr.length - 1].optionInfo
        ) &&
        item.optionArr[item.optionArr.length - 1].checked
      ) {
        item.optionArr[item.optionArr.length - 1].checked = false;
        this.$forceUpdate();
      } else {
        this.$forceUpdate();
      }
      item.answerArr = [];
      item.optionIdArr = [];
      item.optionScoreArr = [];
      item.optionArr.forEach((option) => {
        if (option.checked) {
          item.answerArr.push(option.optionId);
          item.optionIdArr.push(option.optionId);
          item.optionScoreArr.push(option.optionScore);
        }
      });
      item.answer = item.answerArr.join(',');
      item.optionId = item.optionIdArr.join(',');
      item.optionScore = item.optionScoreArr.join(',');
    },
    // 宫格选择
    gridCheck(item, each) {
      item.answer = each;
      this.$forceUpdate();
    },
    // 提交
    submit() {
      let noAnswer = this.questionList[this.sort - 1].questionArray.filter(
        (item) => item.answer == null || item.answer == ''
      );
      if (noAnswer.length > 0) {
        Toast('请完成当前问卷！');
        return false;
      }
      this.submitLoading = true;
      this.endTime = this.$util.getTimestamp();
      this.answerList = [];
      this.questionList.forEach((item) => {
        item.questionArray.forEach((each) => {
          if (each.answer) {
            this.answerList.push({
              fmQuesObjectiveId: each.fmQuesObjectiveId,
              moduleId: each.moduleId,
              answerType: each.answerType,
              answer: each.answer,
              optionId: each.optionId !== '' ? each.optionId : '',
              optionScore: each.optionScore !== ''  ? each.optionScore : '',
            });
          }
        });
      });

      let params = {
        fmUserId: this.$util.getStorage('fmUserId'), //用户ID
        totalTime: this.endTime - this.startTime,
        myAns: this.answerList,
        quesNum:'0-2'
      };
      saveObjectiveAns(params)
        .then((res) => {
          if (res.code === 0) {
            sessionStorage.setItem(
              'fmProductQuestionId',
              res.data.fmProductQuestionId
            );
            //小题编号
            sessionStorage.setItem('quesNum', res.data.quesNum);
            //是否完成
            sessionStorage.setItem('isFinished', res.data.isFinished);
            if(res.data.isFinished === 1) {
              this.$router.replace({
                path: '/mFinish',
                query: {
                  isFinished: res.data.isFinished,
                },
              });
            } else {
              if(res.data.quesNum === '0-1') {
                this.$router.replace('/mQues');
              } else {
                this.$router.replace({
                  path: '/mFinish',
                  query: {
                    isFinished: res.data.isFinished
                  },
                });
              }
            }
            this.submitLoading = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  created() {
    document.title = '积极心理品质测试';
    this.getQuestion();
  },
  mounted() {
    this.startTime = this.$util.getTimestamp();
  },
};
</script>

<style lang="scss" scoped>
::v-deep input[type='number'] {
  border-radius: 1.3rem;
  text-align: center;
}
.evaluation {
  font-family: 'SYHeiNormal';
  width: 100vw;
  height: 100%;
  background: #ffffff;
  font-size: 0.95rem;
  overflow-y: scroll;
  -webkit-overflow-scrolling:touch;
  .progress {
    width: 100%;
    height: 3.5rem;
    padding: 1rem;
    background: #ffffff;
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    .sortBox {
      text-align: center;
      .sort {
        color: #73986d;
        font-size: 1.3rem;
      }
      .quesLen {
        font-size: 0.9rem;
      }
    }
  }
  .main {
    width: 90%;
    margin: 0 auto;
    padding-top: 4rem;
    padding-bottom: 1rem;
    border-radius: 0.3rem;
    .moduleName {
      font-size: 1rem;
      font-weight: 550;
      line-height: 1.2rem;
    }
    .moduleDesc {
      // color: #666666;
      line-height: 1.4rem;
      font-size: 1rem;
      font-weight: 550;
      margin-bottom: 1.8rem;
    }
    .moduleQues {
      margin-bottom: 2rem;
      .quesInfo {
        width: 100%;
        color: #000000;
        display: inline-block;
        line-height: 1.2rem;
        font-size: 0.95rem;
        font-weight: 400;
        margin-bottom: 1.25rem;
      }
      .ladder {
        text-align: center;
        img {
          width: 85%;
        }
      }
      .score {
        li {
          display: inline-block;
          width: 4rem;
          min-height: 2.6rem;
          padding: 0.65rem 0;
          line-height: 1.3rem;
          text-align: center;
          vertical-align: top;
          color: #73986d;
          background: #eef3ee;
          border-radius: 0.6rem;
          text-align: center;
          margin-right: 1rem;
          margin-bottom: 1.25rem;
        }
        .active {
          color: #ffffff;
          background: #73986d;
          p {
            color: #73986d;
          }
        }
      }
      .radio {
        font-size: 0.95rem;
        li {
          width: 100%;
          margin: 0 auto 0.6rem auto;
          .item {
            min-height: 2.6rem;
            padding: 0.65rem;
            line-height: 1.3rem;
            margin-bottom: 0.5rem;
            color: #73986d;
            background: #edf3ed;
            cursor: pointer;
            text-align: center;
            border-radius: 1.3rem;
          }
          .active {
            color: #ffffff;
            background: #73986d;
          }
        }
      }
      .checkbox {
        font-size: 0.95rem;
        li {
          width: 100%;
          min-height: 2.6rem;
          padding: 0.65rem;
          line-height: 1.3rem;
          border-radius: 1.3rem;
          margin: 0 auto 0.6rem auto;
          color: #73986d;
          background: #edf3ed;
          cursor: pointer;
          text-align: center;
          p {
            .optionInfo {
              width: 15rem;
              display: inline-block;
            }
          }
        }
        ::v-deep .el-checkbox__label {
          width: 16rem;
          color: #73986d;
          display: inline-grid;
          white-space: pre-line;
          word-wrap: break-word;
          overflow: hidden;
          line-height: 1.3rem;
        }
        .active {
          color: #ffffff;
          background: #73986d;
          ::v-deep .el-checkbox__label {
            color: #ffffff;
          }
        }
      }
      .gender {
        width: 100%;
        text-align: center;
        ul {
          width: 100%;
          display: flex;
        }
        .sex {
          width: 48%;
          height: 2.6rem;
          line-height: 2.6rem;
          color: #73986d;
          background: #eef3ee;
          border-radius: 2.8rem;
          display: inline-block;
          text-align: center;
          font-size: 0.9rem;
          cursor: pointer;
          margin: 0 0.6rem;
          img {
            width: 1.5rem;
            margin-top: 0.6rem;
            margin-right: 0.5rem;
          }
        }
        .active {
          color: #ffffff;
          border-radius: 1.5rem;
          background: #73986d;
        }
      }
      .slider {
        width: 18rem;
        margin-left: 0.5rem;
        line-height: 2rem;
        .minute {
          display: inline-block;
          line-height: 1rem;
          color: #73986d;
          font-size: 0.8rem;
        }
      }
      .inputBox {
        margin: 1.5rem 0;
        .unit {
          display: inline-block;
          margin-left: 0.7rem;
          font-size: 0.9rem;
        }
      }
      .selectBox {
        ::v-deep .el-select {
          width: 6.5rem;
        }
        ::v-deep .el-select .el-input__inner {
          border-radius: 1.3rem;
        }
        span {
          color: #666666;
          display: inline-block;
          font-size: 0.8rem;
          margin-right: 1rem;
          margin-left: 0.5rem;
        }
      }
      // .grid {
      //   width: 100%;
      //   .colItem {
      //     cursor: pointer;
      //     .bg-purple {
      //       height: 2rem;
      //       margin-bottom: 0.3rem;
      //       background: #edf3ed;
      //     }
      //     .active {
      //       height: 2rem;
      //       margin-bottom: 0.3rem;
      //       background: #73986d;
      //     }
      //   }
      // }
      .grid {
        width: 21rem;
        margin: 0 auto;
        font-size: 0.8rem;
        .blackboard {
          width: 18rem;
          height: 1.5rem;
          line-height: 1.5rem;
          text-align: center;
          border-radius: 1.5rem;
          background: #689a6a;
          color: #ffffff;
          margin-left: 2.5rem;
        }
        .topSite {
          width: 18rem;
          height: 1.7rem;
          line-height: 1.7rem;
          background: #ecf3ec;
          color: #689a6a;
          border: 1px dashed #689a6a;
          border-radius: 2.5rem;
          display: flex;
          margin-top: 0.6rem;
          margin-bottom: 1.2rem;
          margin-left: 2.5rem;
          li {
            width: 20%;
            text-align: center;
            display: inline-block;
          }
        }
        .mainSite {
          // width: 100%;
          display: flex;
          .leftSite {
            width: 2rem;
            text-align: center;
            border-radius: 2rem;
            background: #ecf3ec;
            color: #689a6a;
            margin-right: 0.5rem;
            font-size: 0.75rem;
            border: 1px dashed #689a6a;
            li {
              width: 1.8rem;
              height: 3.7rem;
              padding: 0 0.3rem;
              margin-bottom: 0.35rem;
              display: table;
              span {
                display: table-cell;
                vertical-align: middle;
              }
            }
          }
          .siteList {
            width: 18rem;
            li {
              width: 3.3rem;
              margin: 0 0.3rem 0.3rem 0;
              height: 3.7rem;
              display: inline-block;
              background: #ecf3ec;
              border: 1px solid #689a6a;
              cursor: pointer;
            }
            .active {
              background: #689a6a;
            }
          }
        }
      }
      .imgBox {
        ul {
          text-align: center;
          li {
            width: 19.6rem;
            height: 9rem;
            background: #f7f9f7;
            margin: 0.3rem;
            display: inline-block;
            cursor: pointer;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .active {
            background: #dee8de;
          }
        }
      }
    }
  }
  .bottom {
    width: 90%;
    margin: 0 auto 3rem auto;
    overflow: hidden;
    text-align: center;
    .btns {
      color: #289257;
      font-size: 0.95rem;
      display: inline-block;
      .prev,
      .next,
      .submit {
        width: 9rem;
        height: 2.6rem;
        line-height: 2.6rem;
        border-radius: 1.3rem;
        display: inline-block;
      }
      .prev {
        color: #73986d;
        background: #eef3ee;
        margin-right: 1.8rem;
      }
      .next,
      .submit {
        color: #ffffff;
        background: #73986d;
      }
    }
  }
}
</style>
